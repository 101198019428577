<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12"></div>
      <div class="col-md-12">
        <vuestic-widget :headerText="header">
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Sales.jpg"
                  alt="Card image cap"
                >
                <div class="card-img-overlay" style="color: white">
                  <h3>
                    <i class="fa fa-flag"></i>
                  </h3>
                  <h2 style="font-weight:600;font-size:25px">Recarga de Productos</h2>
                </div>
               
                  <ul class="list-group" style="font-weight:600">
                    <li class="list-group-item">
                    
                    </li>
                 
                    <li
                      class="list-group-item"
                    >Concretadas: {{analytic_station.count_total_topup_ok }}</li>
                    <li
                      class="list-group-item"
                    >Devueltas: {{analytic_station.count_total_topup_fail }}</li>
                  </ul>
                </div>
             
            </div>

          

            <div class="col-md-4">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Devices.jpg"
                  alt="Card image cap"
                >
                <div class="card-img-overlay" style="color: white">
                  <h3>
                    <i class="fa fa-tablet"></i>
                  </h3>
                  <h2 style="font-weight:600;font-size:25px">Dispositivos</h2>
                </div>
               
                  <ul class="list-group" style="font-weight:600">
                    <li class="list-group-item">
                      <h4 style="font-weight:600">{{analytic_station.cant_devices }}</h4>
                    </li>

                    <li class="list-group-item">Por Operador: {{analytic_station.perOperator }}</li>
                    <li
                      class="list-group-item"
                    >Avg Recargas: {{analytic_station.avg_topup_device }} USD</li>
                  </ul>
                
              </div>
            </div>

            <div class="col-md-4">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Operator.jpg"
                  alt="Card image cap"
                >
                <div class="card-img-overlay" style="color: white">
                  <h3>
                    <i class="fa fa-id-card"></i>
                  </h3>
                  <h2 style="font-weight:600;font-size:25px">Operadores</h2>
                </div>
                
                  <ul class="list-group" style="font-weight:600">
                    <li class="list-group-item">
                      <h4 style="font-weight:600">{{analytic_station.cant_staff }}</h4>
                    </li>

                    <li class="list-group-item">Avg Tips: {{analytic_station.avg_tips }}</li>
                    <li class="list-group-item">Avg Recargas: {{analytic_station.avg_topup_operator }}</li>
                  </ul>
               
              </div>
            </div>
          </div>
        </vuestic-widget>
        <!-- <vuestic-widget>
         <div class="row">
            <h4 style="font-weight:600;padding:20px">Sales per Day (En desarrollo)</h4>
            <div class="col-md-12">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th colspan="3">2018-11-13</th>
                    <th colspan="3">Total Event</th>
                  </tr>
                  <tr class="bg-success">
                    <th scope="col"></th>
                    <th scope="col">Cant</th>
                    <th scope="col">Virtual</th>
                    <th scope="col">USD</th>
                    <th scope="col">Cant</th>
                    <th scope="col">Virtual</th>
                    <th scope="col">USD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">beer</th>
                    <td>1</td>
                    <td></td>
                    <td>2.00</td>
                    <td>1</td>
                    <td></td>
                    <td>2.00</td>
                  </tr>
                  <tr>
                    <th scope="row">water</th>
                    <td>2</td>
                    <td></td>
                    <td>6.00</td>
                    <td>2</td>
                    <td></td>
                    <td>6.00</td>
                  </tr>
                  <tr>
                    <th scope="row">Totals</th>
                    <td>3</td>
                    <td>0.00</td>
                    <td>8.00</td>
                    <td>3</td>
                    <td>0.00</td>
                    <td>8.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </vuestic-widget>-->
        <div class="row">
          <div class="col-md-6">
            <!-- <vuestic-widget :headerText="headerChart1">
              <div class="row">
                <vuestic-chart v-bind:data="chartData" :options="chartOptions" type="vertical-bar"></vuestic-chart>
               
              </div>
            </vuestic-widget>-->
          </div>

          <div class="col-md-6">
            <!--  <vuestic-widget :headerText="headerChart2">
              <div class="row">
             
                <vuestic-chart v-bind:data="chartData" :options="chartOptions" type="donut"></vuestic-chart>
              </div>
            </vuestic-widget>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TableStation from "../tables/TableStation/TableStation.vue";
import Multiselect from "vue-multiselect";
import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";

// example of data
let chartData = {
  labels: ["January", "February"],
  datasets: [
    {
      label: "GitHub Commits",
      backgroundColor: "#f87979",
      data: [40, 20]
    }
  ]
};

export default {
  name: "AnalyticByStation",
  components: {
    SpringSpinner,
    TableStation,
    Multiselect
  },
  mounted() {
    // checkbox and radios

    this.loadTypestationsActive()
      .then(data => {
        // this.typestationlist = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
    var idstation = this.$route.params.idstation;
    this.loadAnalyticStationProductTopup(idstation)
      .then(data => {
        this.analytic_station = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
  },
  computed: {
    ...mapState({
      //  typestationlist: state => state.typestation.typestations,
      //analytic_station: state => state.analytic.station,
      idevent: state => state.event.event.id
    })
  },
  data() {
    return {
      header: "Analíticas de Estación",
      headerChart1: "Sales per Hour (En desarrollo)",
      headerChart2: "Top Products (En Desarrollo)",
      // typestation: [],
      //  categorystation: [],
      // typestationlist: [],
      name: "",
      number: "",
      analytic_station: null,
      location: "",
      active: 1,
      eventoptions: null,
      //CHART
      chartData: {
        labels: ["Credits", "Virtual"],
        datasets: [
          {
            label: "GitHub Commits",
            backgroundColor: "#f87979",
            data: [0, 24]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  methods: {
    ...mapActions([
      "loadTypestationsActive",
      "loadAnalyticStationProductTopup",
      "loadCategorystations",
      "storeStation",
      "addToastMessage"
    ]),
    backPage: function() {
      this.$router.go(-1);
    },
    saveStaff() {
      this.$validator.validate().then(result => {
        if (result) {
          this.storeStation({
            name: this.name,
            number: this.number,
            location: this.location,
            id_event: this.idevent,
            active: this.active,
            id_type_station: this.typestation.id
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
              setTimeout(() => this.$router.replace("/station"), 2000);
            })
            .catch(data => {
              this.error = data.message;
              this.addToastMessage({
                text: data.message,
                type: "warning"
              });
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
