<template>
  <div class="content">
    <vuestic-widget>

          <div class="row">
      <div class="col-md-7"  v-if="this.configurations.length > 0">
        <ul class="list-group">
             <li class="list-group-item" v-for="config in this.configurations">
                 <div class="row" style="font-size:24px;">
                 <div class="col-md-10">
                      <label style="font-weight:600"> {{ config.name }} :</label> {{ config.value }}
                 </div>
                 <div class="col-md-2">
                        
                        <span class="btn btn-primary btn-micro" v-on:click="loadItems(config);">
                            <i class="fa fa-pencil valid-icon icon-right input-icon"></i>
                        </span>
                    
                 </div>
                 </div>

              
            </li>
         
        </ul>
      </div>
      <div class="col-md-7"  v-else>
       
       <h3>No hay configuraciones almacenadas para esta estacion</h3>
      </div>
      <div class="col-md-5">
             <div class="col-md-12">
            <vuestic-widget :headerText="$t('view.configstation.update')">
               
                 <div class="row">
                    <div class="col-md-6">
                        <div class="form-group with-icon-right">
                            <div class="input-group">
                                <input id="name" name="name" v-model="name" disabled/>
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="name"></label><i class="bar"></i>
                                <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group with-icon-right">
                            <div class="input-group">
                                <input id="value" name="value" v-model="value" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="name">{{'view.configstation.fields.value' | translate}} </label><i class="bar"></i>
                              
                                <small v-show="errors.has('value')" class="help text-danger">
                                                        {{ errors.first('value') }}
                                                    </small>
                            </div>
                        </div>
                     

                    </div>
                     
                </div>
                <div class="row">
                    <div class="col-md-8">
                    </div>

                </div>
                <div class="row">
                    <button type="button" v-on:click="editConfigStation" class="btn btn-primary btn-micro">{{$t('view.configstation.update')}}</button>
                </div>

            </vuestic-widget>

        </div>

      </div>
    </div>
    </vuestic-widget>

  
  </div>
</template>

<script>
import Vue from "vue";

import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";
export default {
  name: "Table",
  components: {
    SpringSpinner,
  
  },
  data() {
    return {
      namestation: null,
      name:null,
      value:null,
      idconfig:null,
      configurations:[],
    };
  },
  mounted() {
      this.formLoadConfigStation();
  },
  methods: {
    ...mapActions(["loadConfigStations","updateConfigStation","addToastMessage"]),
    back() {
      this.$router.go(-1);
      // this.$router.push('/')
    },   
    editConfigStation() {
               if(this.name === null || this.value === null || this.idconfig === null)
               {
                    this.addToastMessage({
                                    text: "Por favor selecciona un elemento para editar",
                                    type: "warning"
                                });
               }else{
                        var id = this.$route.params.id;                       
                        this.updateConfigStation({                          
                            value:        this.value,                           
                            id:              this.idconfig
                        })
                            .then(data => {
                                this.addToastMessage({
                                    text: data.message,
                                    type: "success"
                                });
                               this.name = null;
                               this.value = null;
                               this.configurations= null;
                               this.formLoadConfigStation();
                            })
                            .catch(data => {
                                this.error  = data.message;
                                this.errors = data.errors || {};
                            });
                        //this.$refs.vuetable.refresh();
               }  
            },
    loadItems(config) {
    
     this.idconfig=config.id;
     this.name=config.name;
     this.value=config.value;
    },
    formLoadConfigStation()
    {
         var id = this.$route.params.idstation;
    if (id !== null) {
      this.loadConfigStations(id)
        .then(data => {
          this.configurations = data;
        })
        .catch(data => {
          this.addToastMessage({
            text: "Ocurrio un error",
            type: "success"
          });
        });
    } else {
      this.addToastMessage({
        text: "Ocurrio un error",
        type: "success"
      });
    }
    }
  }
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
