<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="$t('view.station.detailtitle')+' '+name" :ButtonBack="true">
         
        </vuestic-widget>
      </div>
      <div class="col-md-12">
          <!-- ONLY STATIONS MONETARY -->
          <div v-if="typestation.id===1 || typestation.id===2">
        <vuestic-tabs class="tabs" :names="['Productos', 'Analíticas']">
          <div slot="Analíticas">
               <AnalyticByStation></AnalyticByStation>

          </div>
          <div slot="Productos">

            <ProductsByStation></ProductsByStation>
          </div>
        </vuestic-tabs>
        </div>

          <div v-if="typestation.id===11">
        <vuestic-tabs class="tabs" :names="['Productos', 'Analíticas']">
          <div slot="Analíticas">
               <AnalyticTopupProduct></AnalyticTopupProduct>

          </div>
          <div slot="Productos">

            <ProductsByStation></ProductsByStation>
          </div>
        </vuestic-tabs>
        </div>
         <div v-if="typestation.id===12">
        <vuestic-tabs class="tabs" :names="['Productos', 'Analíticas']">
          <div slot="Analíticas">
               <AnalyticByStation></AnalyticByStation>

          </div>
          <div slot="Productos">

            <ProductsByStation></ProductsByStation>
          </div>
        </vuestic-tabs>
        </div>
        <!-- ONLY STATIONS EVENT MAGAMENET -->
         <div v-if="typestation.id === 3 || typestation.id === 8  || typestation.id === 7">
        <vuestic-tabs class="tabs" :names="['Analíticas', 'Configuracion']">
          <div slot="Analíticas">
              <AnalyticByStationTopup></AnalyticByStationTopup>

          </div>
          <div slot="Configuracion">
            <div class="container">
              <ConfigStation></ConfigStation>
            </div>
           
            <!--<ProductsByStation></ProductsByStation>-->
          </div>
        </vuestic-tabs>
        </div>
          <div v-if="typestation.id === 4 || typestation.id === 5  || typestation.id === 6|| typestation.id === 9|| typestation.id === 10">
        <vuestic-tabs class="tabs" :names="['Analíticas']">
          <div slot="Analíticas">
             <h3 style="padding:5%">No hay estadisticas para este tipo de Estación</h3>

          </div>
         
        </vuestic-tabs>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TableStation from "../tables/TableStation/TableStation.vue";
//import TableStationMoney from "../tables/TableStation/TableStationMoney.vue";
import TableStationEventManager from "../tables/TableStation/TableStationEventManager.vue";
import ProductsByStation from "./ProductsByStation";
import AnalyticByStation from "./AnalyticByStation";
import AnalyticTopupProduct from "./AnalyticTopupProduct";
import AnalyticByStationTopup from "./AnalyticByStationTopup";
import ConfigStation from "./ConfigStation";
import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";
export default {
  name: "Table",
  components: {
    SpringSpinner,
    TableStation,
   // TableStationMoney,
    TableStationEventManager,
    ProductsByStation,
    AnalyticByStation,
    AnalyticTopupProduct,
    AnalyticByStationTopup,
    ConfigStation
  },
  data() {
    return {
      name: "",
      number: "",
      location: "",
      typestation: ""
    };
  },
  computed: {
    ...mapState({})
  },
  mounted() {
    var id = this.$route.params.idstation;
    if (id !== null) {
      this.loadStation(id)
        .then(data => {
          this.name = data.name;
          this.number = data.number;
          this.location = data.location;
          this.typestation = data.type_station;

          this.active = data.active;
        })
        .catch(data => {
          this.addToastMessage({
            text: "Ocurrio un error",
            type: "success"
          });
        });
    } else {
      this.addToastMessage({
        text: "Ocurrio un error",
        type: "success"
      });
    }
  },
  methods: {
    ...mapActions(["loadStation", "addToastMessage"]),
    back() {
      // this.$router.go(-1);
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
